import { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { useAppSelector } from '@/hooks'
import { Advertisements } from '@/pages/advertisements'
import { Articles } from '@/pages/articles'
import { Auth } from '@/pages/auth'
import { Clients } from '@/pages/clients'
import { Companies } from '@/pages/companies'
import { CreateCompany } from '@/pages/companies/create'
import { EditCompany } from '@/pages/companies/edit'
import { CompanyTypes } from '@/pages/company-types'
import { Employees } from '@/pages/employees'
import { Settings } from '@/pages/settings'

const Routing = () => {
	const auth = useAppSelector(state => state.auth)

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/auth"
					element={
						<PublicRoute auth={auth}>
							<Auth />
						</PublicRoute>
					}
				/>
				<Route path="/">
					<Route
						index
						element={
							<ProtectedRoute auth={auth}>
								<Companies />
							</ProtectedRoute>
						}
					/>
					<Route
						path="create"
						element={
							<ProtectedRoute auth={auth}>
								<CreateCompany />
							</ProtectedRoute>
						}
					/>
					<Route
						path="edit/:id"
						element={
							<ProtectedRoute auth={auth}>
								<EditCompany />
							</ProtectedRoute>
						}
					/>
				</Route>
				<Route
					path="/users"
					element={
						<ProtectedRoute auth={auth}>
							<Clients />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/employees"
					element={
						<ProtectedRoute auth={auth}>
							<Employees />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/company-types"
					element={
						<ProtectedRoute auth={auth}>
							<CompanyTypes />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/articles"
					element={
						<ProtectedRoute auth={auth}>
							<Articles />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/advertisements"
					element={
						<ProtectedRoute auth={auth}>
							<Advertisements />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/settings"
					element={
						<ProtectedRoute auth={auth}>
							<Settings />
						</ProtectedRoute>
					}
				/>
			</Routes>
		</BrowserRouter>
	)
}

type RouteProps = {
	auth?: AuthState
	redirectPath?: string
	children: JSX.Element
	customRedirect?: boolean
}

const ProtectedRoute = ({ auth, children, customRedirect, redirectPath = '/auth' }: RouteProps) => {
	if (!auth?.accessToken || customRedirect) {
		return <Navigate to={redirectPath} replace={true} />
	}

	return children
}

const PublicRoute = ({ auth, children, redirectPath = '/' }: RouteProps) => {
	const [shouldRedirect, setShouldRedirect] = useState(false)

	useEffect(() => {
		if (auth?.accessToken) {
			const timeout = setTimeout(() => {
				setShouldRedirect(true)
			}, 1000)
			return () => clearTimeout(timeout)
		}
	}, [auth])

	if (shouldRedirect) {
		return <Navigate to={redirectPath} replace />
	}

	return children
}

export default Routing
