import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import * as yup from 'yup'

import { Button } from '@/components/button'
import { Input } from '@/components/inputs/input'
import { Spinner } from '@/components/spinner'
import articleService from '@/services/article-service'
import { getTKey } from '@/utils/language'
import ReactQuill from 'react-quill'

interface CreateArticleProps {
	onCreation: () => void
	onCancel: () => void
	articleId: string | null
	className?: string
}

export interface ArticleForm {
	title: string
	image: File
	overviewText: string
	content: string
}

export const CreateArticle = ({
	onCreation,
	onCancel,
	articleId,
	className
}: CreateArticleProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('articles.create')

	const fileInputRef = useRef<HTMLInputElement>(null)
	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object<ArticleForm>().shape({
		title: yup.string().required(t(tKey('errors.title'))),
		overviewText: yup.string().required(t(tKey('errors.overviewText'))),
		content: yup.string().required(t(tKey('errors.content'))),
		image: yup.mixed<File>().when('$type', {
			is: true,
			then: schema => schema.notRequired(),
			otherwise: schema => schema.required(t(tKey('errors.image')))
		})
	})

	const {
		register,
		handleSubmit,
		reset,
		watch,
		setValue,
		control,
		formState: { errors }
	} = useForm<ArticleForm>({
		resolver: yupResolver(schema as any),
		context: { type: !!articleId },
		mode: 'all'
	})

	const articleImage = watch('image')

	useEffect(() => {
		if (articleId) {
			articleService.getArticleById(articleId).then(res =>
				reset({
					title: res.title,
					content: res.content,
					overviewText: res.overviewText
				})
			)
		}
	}, [articleId])

	const onSubmit = (data: ArticleForm) => {
		setIsLoading(true)
		const formData = new FormData()
		formData.append('title', data.title)
		formData.append('overviewText', data.overviewText)
		formData.append('content', data.content)

		if (data.image) {
			formData.append('file', data.image)
		}
		if (articleId) {
			articleService
				.updateArticle(articleId, formData)
				.then(res => {
					toast.success(t(tKey('toast.updateSuccess')))
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.updateError')))
				})
				.finally(() => setIsLoading(false))
		} else {
			articleService
				.createArticle(formData)
				.then(res => {
					toast.success(t(tKey('toast.articleSuccess')))
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.articleError')))
				})
				.finally(() => setIsLoading(false))
		}
	}

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target
		if (files && files.length > 0) {
			setValue('image', files[0], { shouldValidate: true })
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={clsx('md:px-8 py-6 max-md:px-5', className)}>
			<div className="flex flex-col gap-y-5">
				<div className="flex flex-col gap-y-5">
					<Input
						register={register}
						errors={errors}
						name="title"
						labelText={t(tKey('labels.title'))}
					/>
					<Input
						register={register}
						errors={errors}
						name="overviewText"
						labelText={t(tKey('labels.overview'))}
					/>

					<div className="flex w-full flex-col gap-y-1">
						<Controller
							control={control}
							name="content"
							render={({ field: { onChange, value } }) => (
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="content"
										className="absolute z-10 -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.content'))}
									</label>
									<ReactQuill
										className="editor agb input"
										theme="snow"
										onChange={onChange}
										value={value}
									/>
								</div>
							)}
						/>
						{errors?.content && (
							<p className="text-xs text-red-500">{errors.content?.message as string}</p>
						)}
					</div>
					<div className="flex w-full flex-col gap-y-1">
						<input
							type="file"
							onChange={handleFileUpload}
							ref={fileInputRef}
							className="hidden w-full appearance-none"
							accept=".jpg,.jpeg,.png"
						/>
						<div
							onClick={() => fileInputRef.current?.click()}
							className="flex w-full cursor-pointer items-center">
							<div className="text-center whitespace-nowrap px-4 rounded-l border border-[#D3E3F1] border-r-0 bg-[#F5F5F5] py-3 text-primary text-sm">
								{t(tKey('labels.chooseImage'))}
							</div>
							<div className="py-3 w-full whitespace-nowrap overflow-hidden text-ellipsis px-4 rounded-r text-sm text-primary border border-[#D3E3F1]">
								{articleImage ? articleImage.name : t(tKey('labels.noImageChosen'))}
							</div>
						</div>
						{errors?.image && (
							<p className="text-xs text-red-500">{errors.image?.message as string}</p>
						)}
					</div>
				</div>

				<div className="flex mt-5 gap-x-6 justify-end">
					<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
						{t(tKey('buttons.cancel'))}
					</button>
					<Button disabled={isLoading} className="text-sm font-bold">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{articleId ? t(tKey('buttons.update')) : t(tKey('buttons.save'))}</span>
						)}
					</Button>
				</div>
			</div>
		</form>
	)
}
