import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import * as yup from 'yup'

import { Button } from '@/components/button'
import { Input } from '@/components/inputs/input'
import { Select } from '@/components/inputs/select'
import { MenuColorPicker } from '@/components/menu'
import { Spinner } from '@/components/spinner'
import advertisementService from '@/services/advertisement-service'
import { getTKey } from '@/utils/language'

interface CreateAdvertisementProps {
	onCreation: () => void
	onCancel: () => void
	advertisementId: string | null
	className?: string
}

export interface AdvertisementForm {
	title: string
	textColor: string
	backgroundImage: File
	link: string
	overview: string
	order: number
	status: string
}

export const CreateAdvertisement = ({
	onCreation,
	onCancel,
	advertisementId,
	className
}: CreateAdvertisementProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('advertisements.create')

	const fileInputRef = useRef<HTMLInputElement>(null)
	const [image, setImage] = useState<string>()
	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object<AdvertisementForm>().shape({
		title: yup.string().required(t(tKey('errors.title'))),
		overview: yup.string().required(t(tKey('errors.overviewText'))),
		link: yup.string().required(t(tKey('errors.link'))),
		order: yup.string().required(t(tKey('errors.order'))),
		status: yup.string().required(t(tKey('errors.status'))),
		backgroundImage: yup.mixed<File>().when('$type', {
			is: true,
			then: schema => schema.notRequired(),
			otherwise: schema => schema.required(t(tKey('errors.image')))
		})
	})

	const {
		register,
		handleSubmit,
		reset,
		watch,
		setValue,
		control,
		formState: { errors }
	} = useForm<AdvertisementForm>({
		resolver: yupResolver(schema as any),
		context: { type: !!advertisementId },
		mode: 'all'
	})

	const advertisementImage = watch('backgroundImage')
	const color = watch('textColor')

	useEffect(() => {
		if (advertisementId) {
			advertisementService.getAdvertisementById(advertisementId).then(res => {
				reset({
					title: res.title,
					status: res.status,
					link: res.link,
					order: res.order,
					textColor: res.textColor,
					overview: res.overview
				})
				setImage(res.backgroundImage)
			})
		}
	}, [advertisementId])

	const onSubmit = (data: AdvertisementForm) => {
		setIsLoading(true)
		const formData = new FormData()
		formData.append('title', data.title)
		formData.append('overview', data.overview)
		formData.append('link', data.link)
		formData.append('order', data.order.toString())
		formData.append('textColor', data.textColor)
		formData.append('status', data.status)

		if (data.backgroundImage) {
			formData.append('file', data.backgroundImage)
		}
		if (advertisementId) {
			advertisementService
				.updateAdvertisement(advertisementId, formData)
				.then(res => {
					toast.success(t(tKey('toast.updateSuccess')))
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.updateError')))
				})
				.finally(() => setIsLoading(false))
		} else {
			advertisementService
				.createAdvertisement(formData)
				.then(res => {
					toast.success(t(tKey('toast.advertisementSuccess')))
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.advertisementError')))
				})
				.finally(() => setIsLoading(false))
		}
	}

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target
		if (files && files.length > 0) {
			setValue('backgroundImage', files[0], { shouldValidate: true })
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={clsx('md:px-8 py-6 max-md:px-5', className)}>
			<div className="flex flex-col gap-y-5">
				<div className="flex flex-col gap-y-5">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
						<Input
							register={register}
							errors={errors}
							name="title"
							labelText={t(tKey('labels.title'))}
						/>
						<Input
							register={register}
							errors={errors}
							name="overview"
							labelText={t(tKey('labels.overview'))}
						/>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
						<Input
							register={register}
							errors={errors}
							name="link"
							labelText={t(tKey('labels.link'))}
						/>
						<Input
							register={register}
							errors={errors}
							name="order"
							disabled
							type="number"
							labelText={t(tKey('labels.order'))}
						/>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
						<Select
							labelText={t(tKey('labels.status'))}
							name="status"
							register={register}
							errors={errors}>
							<option value="">{t(tKey('labels.status'))}</option>
							<option value="aktiv">{t(tKey('labels.active'))}</option>
							<option value="inaktiv">{t(tKey('labels.inactive'))}</option>
						</Select>
						<div className="flex flex-col gap-y-1 cursor-pointer">
							<MenuColorPicker
								color={color}
								setColor={(value: string) =>
									setValue('textColor', value, { shouldValidate: true })
								}>
								<div className="relative flex items-center">
									<Input
										name="textColor"
										readOnly
										register={register}
										labelText={t(tKey('labels.color'))}
									/>
									<div
										style={{ backgroundColor: color }}
										className="absolute left-3 h-4 w-4 -translate-y-px rounded"
									/>
								</div>
							</MenuColorPicker>
							{errors?.textColor && (
								<p className="text-xs text-red-500">{errors.textColor.message}</p>
							)}
						</div>
					</div>
					<div className="flex w-full flex-col gap-y-1">
						<input
							type="file"
							onChange={handleFileUpload}
							ref={fileInputRef}
							className="hidden w-full appearance-none"
							accept=".jpg,.jpeg,.png"
						/>
						<div
							onClick={() => fileInputRef.current?.click()}
							className="flex w-full cursor-pointer items-center">
							<div className="text-center whitespace-nowrap px-4 rounded-l border border-[#D3E3F1] border-r-0 bg-[#F5F5F5] py-3 text-primary text-sm">
								{t(tKey('labels.chooseImage'))}
							</div>
							<div className="py-3 w-full px-4 whitespace-nowrap overflow-hidden text-ellipsis rounded-r text-sm text-primary border border-[#D3E3F1]">
								{advertisementImage ? advertisementImage.name : t(tKey('labels.noImageChosen'))}
							</div>
						</div>
						<div className="flex text-xs text-[#4c6780] items-center gap-1">
							<p>
								{watch('order') === 2 || watch('order') === 3
									? '(300 by 300px)'
									: '(730 by 1100px)'}
							</p>
						</div>
						{errors?.backgroundImage && (
							<p className="text-xs text-red-500">{errors.backgroundImage?.message as string}</p>
						)}
						{advertisementImage ? (
							<img
								src={URL.createObjectURL(advertisementImage)}
								className="object-cover h-[500px] mt-2"
								alt={advertisementImage.name}
							/>
						) : image ? (
							<img src={image} alt={watch('title')} className="object-cover mt-2 h-[500px]" />
						) : (
							<></>
						)}
					</div>
				</div>

				<div className="flex mt-5 gap-x-6 justify-end">
					<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
						{t(tKey('buttons.cancel'))}
					</button>
					<Button disabled={isLoading} className="text-sm font-bold">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{advertisementId ? t(tKey('buttons.update')) : t(tKey('buttons.save'))}</span>
						)}
					</Button>
				</div>
			</div>
		</form>
	)
}
