import axios from '@/services/axios'

const getAdvertisements = (): Promise<Advertisement[]> => {
	return axios.get('/advertisements').then(response => response.data)
}

const getAdvertisementById = (advertisementId: string): Promise<Advertisement> => {
	return axios.get(`/advertisements/${advertisementId}`).then(response => response.data)
}

const createAdvertisement = (data: FormData) => {
	return axios
		.post('/advertisements', data, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const updateAdvertisement = (advertisementId: string, data: FormData) => {
	return axios
		.put(`/advertisements/${advertisementId}`, data, {
			headers: { 'Content-Type': 'multipart/form-data' }
		})
		.then(response => response.data)
}

const deleteAdvertisement = (advertisementId: string) => {
	return axios.delete(`/advertisements/${advertisementId}`).then(response => response.data)
}

const advertisementService = {
	getAdvertisementById,
	getAdvertisements,
	createAdvertisement,
	updateAdvertisement,
	deleteAdvertisement
}

export default advertisementService
