import { ReactNode, useEffect, useState } from 'react'
import {
	Control,
	Controller,
	FieldErrors,
	FieldValues,
	UseFormClearErrors,
	UseFormRegister,
	UseFormWatch,
	useForm
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import ReactQuill from 'react-quill'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import cond from 'cond-construct'
import * as yup from 'yup'

import { Button } from '@/components/button'
import { MultiCombobox } from '@/components/combo-selector'
import { ImageUpload } from '@/components/image-upload'
import { Input } from '@/components/inputs/input'
import { RadioInput } from '@/components/inputs/radio'
import { Select } from '@/components/inputs/select'
import { AppLayout } from '@/components/layout'
import { Spinner } from '@/components/spinner'
import { Tabs } from '@/components/tabs'
import { useAppSelector } from '@/hooks'
import useIsMobile from '@/hooks/useIsMobile'
import companyService from '@/services/company-service'
import companyTypeService from '@/services/company-type-service'
import { cn } from '@/utils/cn'
import { getTKey } from '@/utils/language'

enum ProfileTabs {
	COMPANYDETAILS,
	GOOGLEMAPS,
	SOCIALMEDIA,
	WORKTIME
}

export interface CompanyForm {
	about_company: string
	about_us: string
	is_super_company: string
	isCompanyRegistered: string
	subCompaniesAllowed?: number
	transportation: string
	agb: string
	phone: string
	fax: string
	email: string
	website: string
	konkordac: string
	gln_number: string
	address: string
	address_no: string
	latitude: number
	longitude: number
	facebook: string
	instagram: string
	twitter: string
	youtube: string
	country: string
	city: string
	uID?: string
	zip: string
	company_type: string[]
	google_maps: string
	google_iframe: string
	company_name: string
	parent_company: string
	marketing: string
	is_active: string
	logo: string
	slider: string
}

export const CreateCompany = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const isMobile = useIsMobile()

	const tKey = getTKey('companies.create')

	const auth = useAppSelector(state => state.auth)
	const isSidebarVisible = useAppSelector(state => state.sidebar.isSidebarVisible)

	const [steps, setSteps] = useState(ProfileTabs.COMPANYDETAILS)
	const [isLoading, setIsLoading] = useState(false)
	const [categories, setCategories] = useState<CompanyType[]>([])
	const [companySlider, setCompanySlider] = useState<string>()
	const [companyLogo, setCompanyLogo] = useState<string>()
	const [logo, setLogo] = useState<File>()
	const [coverPhoto, setCoverPhoto] = useState<File>()
	const [selectedCategories, setSelectedCategories] = useState<string[]>([])

	const schema = yup.object<CompanyForm>().shape({
		company_name: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.required(t(tKey('errors.companyName')))
		}),
		email: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.notRequired().email(t(tKey('errors.validEmail')))
		}),
		phone: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema =>
				schema
					.notRequired()
					.min(9, t(tKey('errors.minPhone')))
					.max(13, t(tKey('errors.maxPhone')))
		}),
		company_type: yup
			.array()
			.test('company_type-test', t(tKey('errors.category')), function (value) {
				if (steps !== ProfileTabs.COMPANYDETAILS) return true
				if (selectedCategories.length === 0) return false
				return true
			}),
		is_super_company: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.required(t(tKey('errors.isSuperCompany')))
		}),
		isCompanyRegistered: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.required(t(tKey('errors.isCompanyRegistered')))
		}),
		uID: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema =>
				schema.test('uID-validation', t(tKey('errors.uID')), function (value) {
					if (this.parent.isCompanyRegistered !== 'true') return true
					if (!value) return false
					const uidPattern = /^CHE-\d{3}\.\d{3}\.\d{3}$/
					if (!uidPattern.test(value)) {
						return this.createError({
							path: 'uID',
							message: t(tKey('errors.validuid'))
						})
					}
					return true
				})
		}),
		subCompaniesAllowed: yup
			.number()
			.transform((value, originalValue) => {
				return originalValue === '' ? undefined : value
			})
			.test('sub-companies-validation', t(tKey('errors.subCompanies')), function (value) {
				if (steps !== ProfileTabs.COMPANYDETAILS) return true
				if (this.parent.is_super_company !== 'true') return true
				if (!value) return false
				if (value <= 0) {
					return this.createError({
						path: 'subCompaniesAllowed',
						message: t(tKey('errors.minSubCompanies'))
					})
				}
				if (typeof value !== 'number') {
					return this.createError({
						path: 'subCompaniesAllowed',
						message: t(tKey('errors.validSubCompanies'))
					})
				}
				return true
			}),
		about_company: yup
			.string()
			.test('about_company-test', t(tKey('errors.aboutCompany')), function (value) {
				if (steps !== ProfileTabs.COMPANYDETAILS) return true
				if (!value) return false
				if (value === '<p><br></p>') return false
				return true
			}),
		agb: yup.string().test('agb-test', t(tKey('errors.agb')), function (value) {
			if (steps !== ProfileTabs.COMPANYDETAILS) return true
			return true
		}),
		about_us: yup.string().notRequired(),
		transportation: yup.string().notRequired(),
		website: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.required(t(tKey('errors.website')))
		}),
		konkordac: yup.string().notRequired(),
		fax: yup.string().notRequired(),
		address: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.required(t(tKey('errors.address')))
		}),
		marketing: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.required(t(tKey('errors.allowAppointments')))
		}),
		address_no: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.notRequired()
		}),
		zip: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.required(t(tKey('errors.zip')))
		}),
		google_review_link: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.required(t(tKey('errors.googleReview')))
		}),
		city: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.required(t(tKey('errors.city')))
		}),
		country: yup.string().when('$step', {
			is: ProfileTabs.COMPANYDETAILS,
			then: schema => schema.required(t(tKey('errors.country')))
		}),
		gln_number: yup.string().notRequired(),
		google_iframe: yup.string().when('$step', {
			is: ProfileTabs.GOOGLEMAPS,
			then: schema => schema.required(t(tKey('errors.googleIframe')))
		}),
		google_map: yup.string().when('$step', {
			is: ProfileTabs.GOOGLEMAPS,
			then: schema => schema.required(t(tKey('errors.googleMap')))
		}),
		facebook: yup.string().notRequired(),
		linkedin: yup.string().notRequired(),
		instagram: yup.string().notRequired(),
		youtube: yup.string().notRequired(),
		longitude: yup
			.number()
			.typeError(t(tKey('errors.validLongitude')))
			.transform((value, originalValue) => {
				return originalValue === '' ? undefined : value
			})
			.notRequired(),
		latitude: yup
			.number()
			.typeError(t(tKey('errors.validLatitude')))
			.transform((value, originalValue) => {
				return originalValue === '' ? undefined : value
			})
			.notRequired()
	})

	const {
		register,
		control,
		watch,
		clearErrors,
		handleSubmit,
		formState: { errors }
	} = useForm<CompanyForm>({
		resolver: yupResolver(schema as any),
		context: { step: steps },
		mode: 'all'
	})

	useEffect(() => {
		companyTypeService.getCompanyTypes().then(res => {
			setCategories(res)
		})
	}, [])

	const companyTabs = ['companyDetails', 'googleMaps', 'socialMedia']

	const nextStep = handleSubmit(data => {
		setSteps(prev => prev + 1)
	})

	const onSubmit = handleSubmit((data: any) => {
		setIsLoading(true)
		const formData = new FormData()
		for (const key in data) {
			if (Object.prototype.hasOwnProperty.call(data, key)) {
				if (key === 'company_type') continue
				const value = (data as { [key: string]: any })[key]
				formData.append(key, value)
			}
		}
		for (const category of selectedCategories) {
			formData.append('company_type[]', category)
		}
		if (logo) {
			formData.append('logo', logo)
		}
		if (coverPhoto) {
			formData.append('slider', coverPhoto)
		}
		companyService
			.createCompany(formData)
			.then(() => {
				toast.success(t(tKey('toast.createSuccess')))
				navigate('/')
			})
			.catch(error => {
				if (error?.response?.data?.message) {
					return toast.error(error?.response?.data?.message)
				}
				toast.error(t(tKey('toast.createError')))
			})
			.finally(() => setIsLoading(false))
	})

	const renderComponent = cond([
		[
			steps === ProfileTabs.COMPANYDETAILS,
			() => (
				<CompanyDetailsForm
					{...{
						errors,
						register,
						clearErrors,
						control,
						watch,
						categories,
						selectedCategories,
						setSelectedCategories,
						companyCover: companySlider as string,
						companyLogo: companyLogo as string,
						setLogo,
						setCover: setCoverPhoto
					}}
				/>
			)
		],
		[
			steps === ProfileTabs.GOOGLEMAPS,
			() => (
				<GoogleMapsForm
					{...{
						errors,
						register
					}}
				/>
			)
		],
		[
			steps === ProfileTabs.SOCIALMEDIA,
			() => (
				<SocialMediaForm
					{...{
						errors,
						register
					}}
				/>
			)
		]
	])

	return (
		<AppLayout isHeaderVisible={!isMobile} title={t(tKey('titles.profile'))}>
			<div className="md:pt-6 pt-5">
				<h1 className="text-primary font-domine font-bold md:hidden pb-5 px-5">
					{t(tKey('titles.profile'))}
				</h1>
				<div className="border-b border-[#E5E7EB] overflow-x-auto block pb-px px-5 max-md:mr-4 md:px-6">
					<Tabs
						tabs={companyTabs}
						setTab={(value: string) =>
							companyTabs.indexOf(value) < steps && setSteps(companyTabs.indexOf(value))
						}
						selectedTab={companyTabs[steps]}
					/>
				</div>
				<form
					onSubmit={event => {
						if (steps === ProfileTabs.SOCIALMEDIA) {
							onSubmit(event)
						} else {
							nextStep(event)
						}
					}}>
					<>{renderComponent as ReactNode}</>
					<div
						className={cn(
							'fixed w-full bottom-0 z-30 bg-white py-3 px-6 flex justify-center md:justify-end gap-x-3.5 border-t border-[#1232581A]',
							{ 'md:-translate-x-[256px]': isSidebarVisible }
						)}>
						{steps === ProfileTabs.SOCIALMEDIA && (
							<button className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] text-primary-light rounded border border-primary font-semibold">
								{isLoading ? (
									<div className="flex items-center justify-center gap-x-5">
										<Spinner />
										<span className="animate-pulse whitespace-nowrap">
											{t(tKey('buttons.pleaseWait'))}
										</span>
									</div>
								) : (
									<span>{t(tKey('buttons.create'))}</span>
								)}
							</button>
						)}
						{steps < ProfileTabs.SOCIALMEDIA && (
							<Button
								type="submit"
								className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] font-semibold rounded border border-primary">
								{t(tKey('buttons.next'))}
							</Button>
						)}
					</div>
				</form>
			</div>
		</AppLayout>
	)
}

interface FormProps {
	register: UseFormRegister<CompanyForm>
	errors: FieldErrors<FieldValues>
	control?: Control<CompanyForm, any>
	watch?: UseFormWatch<CompanyForm>
	clearErrors?: UseFormClearErrors<CompanyForm>
}

interface CompanyDetailsFormProps extends FormProps {
	categories: CompanyType[]
	selectedCategories: string[]
	setSelectedCategories: (categories: string[]) => void
	setLogo: (file: File) => void
	setCover: (file: File) => void
	companyLogo: string
	companyCover: string
}

const CompanyDetailsForm = ({
	register,
	errors,
	clearErrors,
	control,
	watch,
	categories,
	selectedCategories,
	setSelectedCategories,
	setLogo,
	setCover,
	companyCover,
	companyLogo
}: CompanyDetailsFormProps) => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()

	const isSuperCompany = watch?.('is_super_company')
	const isCompanyRegistered = watch?.('isCompanyRegistered')

	const tKey = getTKey('companies.create')

	return (
		<div className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl mx-5 md:mx-6 p-3 md:p-6 flex flex-col max-md:mb-[81px] mb-24 gap-y-24 md:gap-y-12">
				<div className="flex flex-col gap-y-4 md:gap-y-5">
					<h1 className="text-primary font-domine font-bold md:text-lg">
						{t(tKey('titles.companyDetails'))}
					</h1>
					<p className="text-sm">{t(tKey('labels.message'))}</p>
					<div className="relative flex gap-x-8 w-full">
						{isMobile && (
							<div className="md:hidden h-[120px] w-[110px] absolute translate-y-20 translate-x-3 z-20">
								<div className="flex flex-col gap-y-3 items-center">
									<ImageUpload
										name="companyLogo"
										image={companyLogo}
										onUpload={(image: File) => setLogo(image)}
										variant="logo"
									/>
									<p className="text-tertiary text-xs">400 by 400px</p>
								</div>
							</div>
						)}
						<div className="max-md:hidden h-[120px] shrink-0 w-[155px]">
							<div className="flex flex-col gap-y-2 items-start">
								<ImageUpload
									image={companyLogo}
									name="companyLogo"
									onUpload={(image: File) => setLogo(image)}
									variant="logo"
								/>
								<p className="text-tertiary text-sm">400 by 400 pixels</p>
							</div>
						</div>
						<div className="flex flex-col gap-y-2 w-full items-center md:items-start">
							<ImageUpload
								name="companyCoverPhoto"
								image={companyCover}
								onUpload={(image: File) => setCover(image)}
								variant="cover"
							/>
							<p className="text-tertiary ml-8 text-xs md:text-sm">1440 by 200 pixels</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-y-5">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
						<Input
							labelText={t(tKey('labels.companyName'))}
							register={register}
							errors={errors}
							name="company_name"
						/>
						<div className="flex relative flex-col gap-y-1">
							<MultiCombobox
								placeholder={t(tKey('labels.categories'))}
								options={categories.map(category => ({
									label: category.label,
									id: category.label
								}))}
								selectedItems={categories
									.filter(category => selectedCategories.includes(category.label))
									.map(category => ({
										label: category.label,
										id: category.label
									}))}
								onChange={(selectedOptions: { label: string; id: any }[]) => {
									clearErrors?.('company_type')
									const labelCountMap = new Map()
									selectedOptions.forEach(item => {
										const count = labelCountMap.get(item.label) || 0
										labelCountMap.set(item.label, count + 1)
									})
									const uniqueValue = selectedOptions.filter(
										item => labelCountMap.get(item.label) === 1
									)

									setSelectedCategories(uniqueValue.map(val => val.label))
								}}
								className="absolute mt-[50px] w-full"
							/>
							{errors?.company_type && (
								<p className="text-xs text-red-500">{errors.company_type?.message as string}</p>
							)}
						</div>
					</div>

					<div className={cn('grid grid-cols-1 gap-y-5 gap-x-5 md:grid-cols-2')}>
						<Select
							name="isCompanyRegistered"
							register={register}
							errors={errors}
							labelText={t(tKey('labels.isCompanyRegistered'))}>
							<option value="">{t(tKey('labels.isCompanyRegistered'))}</option>
							<option value="true">{t(tKey('labels.yes'))}</option>
							<option value="false">{t(tKey('labels.no'))}</option>
						</Select>

						{isCompanyRegistered === 'true' && (
							<Input
								name="uID"
								labelText={t(tKey('labels.uID'))}
								register={register}
								errors={errors}
							/>
						)}

						<Select
							name="is_super_company"
							register={register}
							errors={errors}
							labelText={t(tKey('labels.isSuperCompany'))}>
							<option value="">{t(tKey('labels.isSuperCompany'))}</option>
							<option value="true">{t(tKey('labels.yes'))}</option>
							<option value="false">{t(tKey('labels.no'))}</option>
						</Select>

						{isSuperCompany === 'true' && (
							<Input
								name="subCompaniesAllowed"
								type="number"
								labelText={t(tKey('labels.subCompaniesAllowed'))}
								register={register}
								errors={errors}
							/>
						)}
					</div>

					<div className="flex w-full flex-col gap-y-1">
						<Controller
							control={control}
							name="about_company"
							render={({ field: { onChange, value } }) => (
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="about_company"
										className="absolute z-10 -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.aboutCompany'))}
									</label>
									<ReactQuill
										className="editor agb input"
										theme="snow"
										onChange={onChange}
										value={value}
									/>
								</div>
							)}
						/>
						{errors?.about_company && (
							<p className="text-xs text-red-500">{errors.about_company?.message as string}</p>
						)}
					</div>

					<div className="flex w-full flex-col gap-y-1">
						<Controller
							control={control}
							name="about_us"
							render={({ field: { onChange, value } }) => (
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="about_us"
										className="absolute z-10 -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.aboutUs'))}
									</label>
									<ReactQuill
										className="editor agb input"
										theme="snow"
										onChange={onChange}
										value={value}
									/>
								</div>
							)}
						/>
					</div>

					<div className="flex w-full flex-col gap-y-1">
						<Controller
							control={control}
							name="transportation"
							render={({ field: { onChange, value } }) => (
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="transportation"
										className="absolute z-10 -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.nearestLandmarks'))}
									</label>
									<ReactQuill
										className="editor agb input"
										theme="snow"
										onChange={onChange}
										value={value}
									/>
								</div>
							)}
						/>
					</div>

					<div className="flex w-full flex-col gap-y-1">
						<Controller
							control={control}
							name="agb"
							render={({ field: { onChange, value } }) => (
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="agb"
										className="absolute z-10 font-bold -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.agb'))}
									</label>
									<ReactQuill
										className="editor agb input"
										theme="snow"
										onChange={onChange}
										value={value}
									/>
								</div>
							)}
						/>
						{errors?.agb && <p className="text-xs text-red-500">{errors.agb?.message as string}</p>}
					</div>

					<div className="flex flex-col gap-y-1">
						<label htmlFor="marketing" className="text-primary text-sm">
							{t(tKey('labels.allowAppointments'))}
						</label>
						<Controller
							control={control}
							name="marketing"
							render={({ field: { onChange, value } }) => (
								<div className="flex flex-col">
									<div className="flex flex-row gap-x-5">
										<RadioInput
											labelText={t(tKey('labels.yes'))}
											onChange={onChange}
											name="marketing"
											register={register}
											value="1"
											checked={value === '1'}
										/>
										<RadioInput
											labelText={t(tKey('labels.no'))}
											onChange={onChange}
											register={register}
											name="marketing"
											value="0"
											checked={value === '0'}
										/>
									</div>
									{errors?.marketing && (
										<p className="text-xs text-red-500 mt-1">
											{errors.marketing.message as string}
										</p>
									)}
								</div>
							)}
						/>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
						<Input register={register} errors={errors} name="email" labelText="Email" />
						<Controller
							control={control}
							name="phone"
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<div className="flex flex-col">
									<PhoneInput
										numberInputProps={{
											className:
												'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
										}}
										placeholder="Enter phone number"
										defaultCountry="CH"
										value={value}
										error={error}
										onChange={onChange}
									/>
									{errors?.phone && (
										<p className="text-xs text-red-500 mt-1">{errors.phone.message as string}</p>
									)}
								</div>
							)}
						/>
					</div>
					<div className="grid md:grid-cols-2 grid-cols-1 max-md:gap-y-5 md:gap-x-5">
						<Input
							register={register}
							errors={errors}
							name="website"
							labelText={t(tKey('labels.website'))}
						/>
						<Select
							name="country"
							register={register}
							errors={errors}
							labelText={t(tKey('labels.country'))}>
							<option value="ch">{t(tKey('labels.switzerland'))}</option>
							<option value="de">{t(tKey('labels.deutschland'))}</option>
							<option value="at">{t(tKey('labels.osterreich'))}</option>
						</Select>

						<div className="hidden">
							<Input
								register={register}
								errors={errors}
								name="konkordac"
								labelText={t(tKey('labels.konkordac'))}
							/>
						</div>
					</div>

					<div className="flex flex-col gap-y-5 md:flex-row gap-x-5">
						<Input
							register={register}
							errors={errors}
							name="address"
							labelText={t(tKey('labels.address'))}
						/>
						<Input
							register={register}
							errors={errors}
							type="number"
							inputMode="numeric"
							min="0"
							labelText={t(tKey('labels.addressNo'))}
							name="address_no"
						/>
					</div>

					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.city'))}
							name="city"
						/>
						<Input
							register={register}
							errors={errors}
							type="number"
							inputMode="numeric"
							min="0"
							labelText={t(tKey('labels.zipCode'))}
							name="zip"
						/>
					</div>

					<div className="grid md:grid-cols-2 grid-cols-1 max-md:gap-y-5 md:gap-x-5">
						<Input
							register={register}
							errors={errors}
							name="google_review_link"
							labelText={t(tKey('labels.googleReview'))}
						/>
						<Input
							register={register}
							errors={errors}
							name="fax"
							labelText={t(tKey('labels.fax'))}
						/>
					</div>

					<div className="hidden flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="gln_number"
							labelText={t(tKey('labels.gln'))}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

const GoogleMapsForm = ({ register, errors }: FormProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('companies.create')

	return (
		<div className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl mx-5 md:mx-6 p-3 md:p-6 flex flex-col gap-y-24 md:gap-y-12">
				<div className="flex flex-col gap-y-4 md:gap-y-5">
					<h1 className="text-primary font-domine font-bold md:text-lg">
						{t(tKey('titles.googleMaps'))}
					</h1>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="google_map"
							labelText={t(tKey('labels.locationMap'))}
						/>
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.iframe'))}
							name="google_iframe"
						/>
					</div>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							type="number"
							step="any"
							labelText={t(tKey('labels.longitude'))}
							name="longitude"
						/>
						<Input
							register={register}
							errors={errors}
							type="number"
							step="any"
							labelText={t(tKey('labels.latitude'))}
							name="latitude"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

const SocialMediaForm = ({ register, errors }: FormProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('companies.create')

	return (
		<div className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl max-md:mb-[81px] mx-5 md:mx-6 p-3 md:p-6 flex flex-col gap-y-24 md:gap-y-12">
				<div className="flex flex-col gap-y-4 md:gap-y-5">
					<h1 className="text-primary font-domine font-bold md:text-lg">
						{t(tKey('titles.socialMedia'))}
					</h1>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="facebook"
							labelText={t(tKey('labels.facebook'))}
						/>
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.linkedin'))}
							name="linkedin"
						/>
					</div>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="instagram"
							labelText={t(tKey('labels.instagram'))}
						/>
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.youtube'))}
							name="youtube"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
